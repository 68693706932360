import { setAmplitudeUserId } from '../lib/amplitude';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAccount } from 'wagmi';

const Web3Context = createContext();

export const useWeb3 = () => useContext(Web3Context);

export const Web3Provider = ({ children }) => {
  const { address, isConnected, status } = useAccount();
  const [account, setAccount] = useState(null);

  useEffect(() => {
    if (isConnected && address) {
      connectWallet(address);
    }
  }, [isConnected, address]);

  const connectWallet = useCallback((account) => {
    setAccount(account);
    setAmplitudeUserId(account);
  }, []);

  const value = {
    account,
    isConnected,
    status,
    connectWallet,
  };

  return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
};
