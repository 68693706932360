import { AuthProvider } from '../contexts/authContext';
import { MouseMoveProvider } from '../contexts/mouse-move-context';
import { initializeAmplitude } from '../lib/amplitude';
import '@rainbow-me/rainbowkit/styles.css';
import { store } from '@redux/store';
import '@styles/index.scss';
import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

const DynamicWalletProvider = dynamic(() => import('@providers/walletProvider'), {
  ssr: false,
});

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    initializeAmplitude(process.env.NEXT_AMPLITUDE_KEY);
  }, []);

  return (
    <React.Fragment>
      <DynamicWalletProvider>
        <AuthProvider>
          <Provider store={store}>
            <MouseMoveProvider>
              <Component {...pageProps} />
            </MouseMoveProvider>
          </Provider>
        </AuthProvider>
      </DynamicWalletProvider>
    </React.Fragment>
  );
}

export default MyApp;
