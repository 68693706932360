import { AUTH_TOKEN_KEY } from '@utils/constants';
import { getLocalStorage, setLocalStorage } from '@utils/localstorage';
import { useWeb3 } from './web3Context';
import React, { createContext, useEffect, useRef, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [initialCheckComplete, setInitialCheckComplete] = useState(false);
  const { isConnected, status } = useWeb3();
  const initialCheckRef = useRef(true);

  useEffect(() => {
    const authToken = getLocalStorage(AUTH_TOKEN_KEY);
    if (authToken) {
      setIsAuthenticated(true);
    }
    setInitialCheckComplete(true);
  }, []);

  useEffect(() => {
    if (!initialCheckComplete) {
      return;
    }
    
    if (initialCheckRef.current) {
      if (status === 'connected') {
        const authToken = getLocalStorage(AUTH_TOKEN_KEY);
        setIsAuthenticated(!!authToken);
      }
      initialCheckRef.current = false;
      return;
    }
    
    if (isConnected) {
      const authToken = getLocalStorage(AUTH_TOKEN_KEY);
      setIsAuthenticated(!!authToken);
    } else {
      const timeoutId = setTimeout(() => {
        if (!isConnected) {
          setIsAuthenticated(false);
          setLocalStorage(AUTH_TOKEN_KEY, null);
        }
      }, 1000);
      
      return () => clearTimeout(timeoutId);
    }
  }, [isConnected, initialCheckComplete, status]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {initialCheckComplete ? children : <p>Loading...</p>}
    </AuthContext.Provider>
  );
};
