export const apiUrlProxy = process.env.NEXT_ARCHIVE_API_PROXY;
export const apiUrlMain = process.env.NEXT_ARCHIVE_API_MAIN;
export const DEMO_WALLET = '0x006cc1b89e9b68e08eec14a514d17b47b363acce';
export const ETHEREUM_MAINNET_ID = '1';
export const AUTH_TOKEN_KEY = 'authToken';
export const protocolManifestMap = {
  uniswap_v3: 'uniswap_v3',
  clipper_v1: 'clipper_v1',
  balancer_v2: 'balancer_v2',
  ichi_v1: 'ichi_v1',
  aave_v3: 'aave_v3',
  aave_v2: 'aave_v2',
  compound_v3: 'compound_v3',
  spark_v1: 'spark_v1',
  bril_v1: 'bril_v1',
  fluid_v1: 'fluid_v1',
  morpho_v1: 'morpho_v1',
};
