import { identify, init, setUserId, track } from '@amplitude/analytics-browser';

let amplitudeInstance = null;

export const initializeAmplitude = (apiKey) => {
  console.log('Initializing Amplitude with API key:', apiKey);
  if (!amplitudeInstance) {
    try {
      init(apiKey);
      amplitudeInstance = true;
      console.log('Amplitude initialized successfully');
    } catch (error) {
      console.error('Failed to initialize Amplitude:', error);
    }
  } else {
    console.log('Amplitude already initialized');
  }
};

export const logEvent = (eventName, eventProperties) => {
  if (!amplitudeInstance) {
    console.error('Amplitude is not initialized');
    return;
  }
  try {
    track(eventName, eventProperties);
  } catch (error) {
    console.error('Failed to log event:', error);
  }
};

export const setAmplitudeUserId = (userId) => {
  if (!amplitudeInstance) {
    console.error('Amplitude is not initialized');
    return;
  }
  try {
    setUserId(userId);
    console.log('User ID set:', userId);
  } catch (error) {
    console.error('Failed to set user ID:', error);
  }
};

export const identifyUser = (userId, userProperties) => {
  if (!amplitudeInstance) {
    console.error('Amplitude is not initialized');
    return;
  }
  try {
    identify(userId, userProperties);
    console.log('User identified:', userId, userProperties);
  } catch (error) {
    console.error('Failed to identify user:', error);
  }
};
