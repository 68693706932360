import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userIncomeData: null,
};

export const incomeReportSlice = createSlice({
  name: 'incomeReport',
  initialState,
  reducers: {
    set_user_income: (state, { payload }) => {
      state.userIncomeData = payload;
    },
  },
});

export const { set_user_income } = incomeReportSlice.actions;
export const selectUserIncomeData = (state) => state.courses.userIncomeData;
export default incomeReportSlice.reducer;
